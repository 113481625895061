import _logo from './logo.svg';
import _logoFull from './logo_full.svg';
import _Contraband from './Contraband';
import _Insurance from './Insurance';

export const LOGO = _logo;
export const LOGO_FULL = _logoFull;
export const Contraband = _Contraband;
export const Insurance = _Insurance;

export { SHIPPING_SERVICES } from './shipping_services';

export const TELEGRAM_BOT_API_KEY = '1722391470:AAFLrC2GN7cR9FUU48zOTrP2bGxl89RksIQ';
export const TELEGRAM_BOT_CHAT_ID = -1001374095284;
export const TELEGRAM_BOT_URI = `https://api.telegram.org/bot${TELEGRAM_BOT_API_KEY}/sendMessage`;

export const COMPANY_FULL_NAME = '国际集运';
export const COMPANY_SHORT_NAME = '国际集运';
export const COMPANY_WECHAT_ID = 'wechat_id';
export const COMPANY_WORKING_HOURS = '周一至周五 9:00-21:00';
export const COMPANY_PACKING_DAYS_GUARANTEE = 3;
export const WAREHOUSE_ADDRESS = '广州市白云区测试街道888号';
export const WAREHOUSE_PHONE = '1888888888';
export const WAREHOUSE_ZIP_CODE = '200000';

export const COUNTRIES_SUPPORTED = {
  US: '美国',
  JP: '日本',
};

export const CURRENCIES_SUPPORTED = {
  CNY: '人民币',
  USD: '美元',
};

type PaymentInfoType = {
  show: boolean,
  alipay?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
  taobao?: {
    enabled: boolean,
    link?: string,
  },
  wechatPay?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
  paypal?: {
    enabled: boolean,
    account?: string,
    accountName?: string,
  },
};

export const PAYMENT_INFO: PaymentInfoType = {
  show: false,
};

export const ADMIN_LOGIN_PAGE_SHOW_LOGO = false;
export const ADMIN_LOGIN_PAGE_SHOW_COMPANY_NAME = true;
export const TICKET_SYSTEM_ENABLED = false;
export const SHOW_INSURANCE_PAGE = false;
export const EMAIL_NOTIFICATION_ENABLED = false;
export const ORDER_UPLOAD_IMAGE = true;
export const PACKAGE_UPLOAD_IMAGE = true;
export const DIRECT_ORDER_SELECT_SERVICE_ENABLED = true;
export const SHOW_SHIPPING_ROUTE_PAGE = true;
export const SHOW_CURRENCY_OPTION_IN_ORDER_PAGE = true;

export const PRICE_PER_PACKAGE = 0.2;
